import React, { useState, useEffect } from 'react'
import './poster.css'
import { FaRegThumbsUp } from "react-icons/fa";
import { FaShareNodes } from "react-icons/fa6";
import { IoImages } from "react-icons/io5";
import { FaComment } from "react-icons/fa";
import { FaSmile } from "react-icons/fa";
import { LuSmilePlus } from "react-icons/lu";
import { IoFilterSharp } from "react-icons/io5";
import { AiFillExclamationCircle } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { MdStarRate } from "react-icons/md";
import { viewAllPost } from '../../api/apiFun';
import { daymonthyearformat } from '../../Helper/dateConverter';

import { RiChatSmile3Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";


const Poster = () => {
    const [viewpost, setPost] = useState({});

      const { t } = useTranslation();
    

    const view = async () => {

        let view = await viewAllPost();
        if (view.status == true) {
            setPost(view?.data)
        }
    };

    useEffect(() => {
        view()
    }, [])

    return (
        <div className='poster'>
            <section className="inner-banner-poster">
                <div className="container">
                    <div className="inner-bannerimg"></div>
                </div>
            </section>
            <div class="container mt-5 mb-5">
                <div class="row d-flex  justify-content-center">
                    <div className='col-lg-4'>
                        <div className='Intro-facebook'>
                            <div className='card px-4 p-3'>
                                <h5>{t('அறிமுகம்')}</h5>
                                <p className=''>{t('விஷயங்களைச் செய்ய நீங்கள் விரும்புவதை இணைக்கவும். இது உங்கள் உலகம்.')}</p>
                                <div className='line1'></div>
                                <div className='d-flex align-items-center '>
                                    <p> <span><AiFillExclamationCircle />
                                    </span>{t('பக்கம் நிறுவனம்')}</p>
                                </div>
                                <div className='d-flex align-items-center '>
                                    <p> <span><FaUserCircle />
                                    </span>{t('இந்தப் பக்கத்திற்கு மெட்டா பொறுப்பு')}</p>
                                </div>
                                <div className='d-flex align-items-center '>
                                    <p> <span><MdStarRate />
                                    </span>{t('மதிப்பீடு 3.1 (3,559 மதிப்புரைகள்)')}<AiFillExclamationCircle />   </p>
                                </div>
                            </div>
                            <div className='card mt-4 px-4 p-4'>
                                <div className='d-flex  justify-content-between'>
                                    <h5>{t('புகைப்படங்கள்')}</h5>
                                    <h5>{t('அனைத்து புகைப்படங்களையும் பார்க்கவும்')}</h5>
                                </div>
                                <div className='line1'> </div>
                                <div className='row mt-3'>
                                    <div className='col-lg-10 m-auto'>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/poster-add.jpg'} className="img-fluid m-auto d-block " alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div className='user-facebook'>
                            <div className='card px-4 p-2'>
                                <div className=' d-flex  justify-content-between'>
                                    <h5>Posts</h5>
                                    <h6><IoFilterSharp />
                                        Filters</h6>
                                </div>
                            </div>

                            {viewpost && viewpost.length > 0 && <>
                                {viewpost.map((e, i) => {
                                    // console.log('eee -->',e.users)
                                    return (
                                        <div class="card mt-3">
                                            <div class="d-flex justify-content-between p-2 px-3">
                                                <div class="profile-images d-flex flex-row align-items-center">
                                                    <img loading="lazy" src={e.users.profile} className="img-fluid  rounded-circle" alt="icon" width="50" />
                                                    <div class="d-flex flex-column ml-2"> <span class="font-weight-bold">{e.users.memberName}</span> <small class="text-primary">{daymonthyearformat(e.createdAt)}</small> </div>
                                                </div>
                                                {/* <div class="d-flex flex-row mt-1 ellipsis">
                                                    <small class="mr-2">20 mins</small>
                                                </div> */}
                                            </div>


                                            <img loading="lazy" src={e.image} className="uplode-images img-fluid  " alt="icon" />
                                            {/* <div class="thumbsup p-2 px-4">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div className='smile'>
                                                        <span className='Frozer'><FaRegThumbsUp /></span>
                                                        <span className='Translate'><FaSmile /></span>
                                                        <span>756</span>
                                                    </div>
                                                    <div className='SmilePlus'>
                                                        <span>525 Share</span>
                                                        <span className='m-2'>967 Comment</span>

                                                    </div>

                                                </div>
                                            </div> */}
                                            <div className='line1'></div>
                                            {/* <div class="p-2 px-4">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span className='Frozer'><FaRegThumbsUp />Like</span>
                                                    <span className='responsible '><FaShareNodes />Share</span>
                                                    <span className='Frozer'><FaComment />Comment</span>
                                                </div>
                                            </div> */}
                                            <div className='line1'></div>
                                            {/* <div className='Comment-set'>
                                                <p>View more comments</p>
                                                <div class="comments">
                                                    <div class="View-comments d-flex flex-row mb-2">
                                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />

                                                        <div class="d-flex flex-column ml-2"> <span class="name">Daniel Frozer</span> <small class="comment-text">I like this alot! thanks alot</small>
                                                            <div class="d-flex flex-row align-items-center status"> <small>Like</small> <small>Reply</small> <small>Translate</small> <small>18 mins</small> </div>
                                                        </div>
                                                    </div>
                                                    <div className='line1'></div>
                                                    <div class=" mt-3 comment-input d-flex justify-content-between " > <input type="text" class="form-control" placeholder='Write a comment…' />
                                                        <div class="fonts"><span className='Frozer'><LuSmilePlus /></span>
                                                            <span className='Translate'><RiChatSmile3Line /></span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                    )
                                })
                                }</>}



                            {/* <div class="card mt-3">
                                <div class="d-flex justify-content-between p-2 px-3">
                                    <div class="profile-images d-flex flex-row align-items-center">
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />
                                        <div class="d-flex flex-column ml-2"> <span class="font-weight-bold">Jeanette Sun</span> <small class="text-primary">29/04/2024 12:52:24 PM</small> </div>
                                    </div>
                                    <div class="d-flex flex-row mt-1 ellipsis">
                                        <small class="mr-2">20 mins</small>
                                    </div>
                                </div>


                                <img loading="lazy" src={process.env.PUBLIC_URL + '/images/new-vijay.jpg'} className="uplode-images img-fluid  " alt="icon" />
                                <div class="thumbsup p-2 px-4">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div className='smile'>
                                            <span className='Frozer'><FaRegThumbsUp /></span>
                                            <span className='Translate'><FaSmile /></span>
                                            <span>756</span>
                                        </div>
                                        <div className='SmilePlus'>
                                            <span>525 Share</span>
                                            <span className='m-2'>967 Comment</span>

                                        </div>

                                    </div>
                                </div>
                                <div className='line1'></div>
                                <div class="p-2 px-4">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span className='Frozer'><FaRegThumbsUp />Like</span>
                                        <span className='responsible '><FaShareNodes />Share</span>
                                        <span className='Frozer'><FaComment />Comment</span>
                                    </div>
                                </div>
                                <div className='line1'></div>
                                <div className='Comment-set'>
                                    <p>View more comments</p>
                                    <div class="comments">
                                        <div class="View-comments d-flex flex-row mb-2">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />

                                            <div class="d-flex flex-column ml-2"> <span class="name">Daniel Frozer</span> <small class="comment-text">I like this alot! thanks alot</small>
                                                <div class="d-flex flex-row align-items-center status"> <small>Like</small> <small>Reply</small> <small>Translate</small> <small>18 mins</small> </div>
                                            </div>
                                        </div>
                                        <div className='line1'></div>
                                        <div class=" mt-3 comment-input d-flex justify-content-between " > <input type="text" class="form-control" placeholder='Write a comment…' />
                                            <div class="fonts"><span className='Frozer'><LuSmilePlus /></span>
                                                <span className='Translate'><RiChatSmile3Line /></span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-3">
                                <div class="d-flex justify-content-between p-2 px-3">
                                    <div class="profile-images d-flex flex-row align-items-center">
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />
                                        <div class="d-flex flex-column ml-2"> <span class="font-weight-bold">Jeanette Sun</span> <small class="text-primary">29/04/2024 12:52:24 PM</small> </div>
                                    </div>
                                    <div class="d-flex flex-row mt-1 ellipsis">
                                        <small class="mr-2">20 mins</small>
                                    </div>
                                </div>


                                <img loading="lazy" src={process.env.PUBLIC_URL + '/images/new-vijay.jpg'} className="uplode-images img-fluid  " alt="icon" />
                                <div class="thumbsup p-2 px-4">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div className='smile'>
                                            <span className='Frozer'><FaRegThumbsUp /></span>
                                            <span className='Translate'><FaSmile /></span>
                                            <span>756</span>
                                        </div>
                                        <div className='SmilePlus'>
                                            <span>525 Share</span>
                                            <span className='m-2'>967 Comment</span>

                                        </div>

                                    </div>
                                </div>
                                <div className='line1'></div>
                                <div class="p-2 px-4">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span className='Frozer'><FaRegThumbsUp />Like</span>
                                        <span className='responsible '><FaShareNodes />Share</span>
                                        <span className='Frozer'><FaComment />Comment</span>
                                    </div>
                                </div>
                                <div className='line1'></div>
                                <div className='Comment-set'>
                                    <p>View more comments</p>
                                    <div class="comments">
                                        <div class="View-comments d-flex flex-row mb-2">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />

                                            <div class="d-flex flex-column ml-2"> <span class="name">Daniel Frozer</span> <small class="comment-text">I like this alot! thanks alot</small>
                                                <div class="d-flex flex-row align-items-center status"> <small>Like</small> <small>Reply</small> <small>Translate</small> <small>18 mins</small> </div>
                                            </div>
                                        </div>
                                        <div className='line1'></div>
                                        <div class=" mt-3 comment-input d-flex justify-content-between " > <input type="text" class="form-control" placeholder='Write a comment…' />
                                            <div class="fonts"><span className='Frozer'><LuSmilePlus /></span>
                                                <span className='Translate'><RiChatSmile3Line /></span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-3">
                                <div class="d-flex justify-content-between p-2 px-3">
                                    <div class="profile-images d-flex flex-row align-items-center">
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />
                                        <div class="d-flex flex-column ml-2"> <span class="font-weight-bold">Jeanette Sun</span> <small class="text-primary">29/04/2024 12:52:24 PM</small> </div>
                                    </div>
                                    <div class="d-flex flex-row mt-1 ellipsis">
                                        <small class="mr-2">20 mins</small>
                                    </div>
                                </div>


                                <img loading="lazy" src={process.env.PUBLIC_URL + '/images/new-vijay.jpg'} className="uplode-images img-fluid  " alt="icon" />
                                <div class="thumbsup p-2 px-4">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div className='smile'>
                                            <span className='Frozer'><FaRegThumbsUp /></span>
                                            <span className='Translate'><FaSmile /></span>
                                            <span>756</span>
                                        </div>
                                        <div className='SmilePlus'>
                                            <span>525 Share</span>
                                            <span className='m-2'>967 Comment</span>

                                        </div>

                                    </div>
                                </div>
                                <div className='line1'></div>
                                <div class="p-2 px-4">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span className='Frozer'><FaRegThumbsUp />Like</span>
                                        <span className='responsible '><FaShareNodes />Share</span>
                                        <span className='Frozer'><FaComment />Comment</span>
                                    </div>
                                </div>
                                <div className='line1'></div>
                                <div className='Comment-set'>
                                    <p>View more comments</p>
                                    <div class="comments">
                                        <div class="View-comments d-flex flex-row mb-2">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png'} className="img-fluid  rounded-circle" alt="icon" width="50" />

                                            <div class="d-flex flex-column ml-2"> <span class="name">Daniel Frozer</span> <small class="comment-text">I like this alot! thanks alot</small>
                                                <div class="d-flex flex-row align-items-center status"> <small>Like</small> <small>Reply</small> <small>Translate</small> <small>18 mins</small> </div>
                                            </div>
                                        </div>
                                        <div className='line1'></div>
                                        <div class=" mt-3 comment-input d-flex justify-content-between " > <input type="text" class="form-control" placeholder='Write a comment…' />
                                            <div class="fonts"><span className='Frozer'><LuSmilePlus /></span>
                                                <span className='Translate'><RiChatSmile3Line /></span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Poster