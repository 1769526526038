import Axios from 'axios';
import toast from 'react-hot-toast';

const accessKey = process.env.REACT_APP_BSJDGSHDKBR

function tosterDisplay(type, text) {

	if(text.length > 0) {

		toast.dismiss();

		(type === 'success')  ? toast.success(<div dangerouslySetInnerHTML={{__html:text}}/>) : toast.error(<div dangerouslySetInnerHTML={{__html:text}}/>);
	}
}

const connBackendReq = {

    apiReq: async function(serviceName, payload = {}, method = 'POST') {

    	var FormmdataApi = ['addmember','createPost','addvoters', 'register']

        let headersConfig = {
    		"Content-Type": FormmdataApi.includes(serviceName.replace('/', '')) ? "multipart/form-data" : "application/json;charset=UTF-8",
			"Access-Control-Allow-Origin": "*",
			'deviceType' : 'website'
	    };

	    if(localStorage.getItem(accessKey) ) {

	    	headersConfig['Authorization'] = "Bearer " + localStorage.getItem(accessKey)
			
	    }

		const baseURL = process.env.REACT_APP_BtHgCwNLyv+serviceName;
		
        return await Axios({
			
			url 	: baseURL,
			method 	: method,
			data 	: payload,
			headers : headersConfig
		
		}).then(async(result) => {

            if(result && result.data) {
				if(result.data.status) {
					let msg = (result.data.msg) ? tosterDisplay('success', result.data.msg) : '';

					if(result.data.auth) {
						localStorage.setItem(accessKey,  result.data.auth);
					}
					return result.data;
				} else  {
					let msg = (result.data.msg) ? tosterDisplay('error', result.data.msg) : '';
					return result.data;
				}
			} else {
				tosterDisplay('error', 'Server busy!')
			}
        })

    }
}


export default connBackendReq; 

