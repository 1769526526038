import apiReq from './apiReq';


export const signup = async (data) => {

    const getResponse = await apiReq.apiReq('register', data, 'POST')
  
    return getResponse;
};

export const sendRegOTP = async (data) => {

    const getResponse = await apiReq.apiReq('sendOTP', data, 'POST')
  
    return getResponse;
};

export const signin = async (data) => {

    const getResponse = await apiReq.apiReq('login', data, 'POST')
  
    return getResponse;
};

export const addMember = async (data) => {

    const getResponse = await apiReq.apiReq('addmember', data, 'POST')
  
    return getResponse;
};

export const viewMember = async (data) => {

    const getResponse = await apiReq.apiReq('viewmember', data, 'POST')
  
    return getResponse;
};

export const memberCard = async (data) => {

    const getResponse = await apiReq.apiReq('membercard', data, 'POST')
  
    return getResponse;
};

export const createPost = async (data) => {
    const getResponse = await apiReq.apiReq('createPost', data, 'POST')
  
    return getResponse;
};

export const viewAllPost = async (data) => {

    const getResponse = await apiReq.apiReq('viewAllPost', data, 'POST')
  
    return getResponse;
};

export const addVoters = async (data) => {

    const getResponse = await apiReq.apiReq('addvoters', data, 'POST')
  
    return getResponse;
};

export const viewVoters = async (data) => {

    const getResponse = await apiReq.apiReq('viewvoters', data, 'POST')
  
    return getResponse;
};

export const announceList = async (data) => {

    const getResponse = await apiReq.apiReq('viewAllAnnounce', data, 'POST')
  
    return getResponse;
};

export const MediaList = async (data) => {

    const getResponse = await apiReq.apiReq('viewMedia', data, 'POST')
  
    return getResponse;
};

export const telecostView = async (data) => {

    const getResponse = await apiReq.apiReq('viewTelecost', data, 'POST')
  
    return getResponse;
};

export const GalleryView = async (data) => {

    const getResponse = await apiReq.apiReq('viewGallery', data, 'POST')
  
    return getResponse;
};

export const DetailsList = async (data) => {

    const getResponse = await apiReq.apiReq('List', data, 'POST')
  
    return getResponse;
};




export const ConferenceView = async (data) => {

    const getResponse = await apiReq.apiReq('viewConference', data, 'POST')
  
    return getResponse;
};

