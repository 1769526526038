import React, { useEffect, useRef, useState, useContext } from "react";
import "./home.css";
import Bannersec from "./Bannersec";
import { FaRegHeart } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import Carousels from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaShareAlt } from "react-icons/fa";
import { Link ,useNavigate} from "react-router-dom";
import useSound from "use-sound";
import { AiFillBank } from "react-icons/ai";
import { FaHospitalUser } from "react-icons/fa6";
import { MdOutlineSocialDistance } from "react-icons/md";
import { AiTwotoneReconciliation } from "react-icons/ai";
import { MdCastForEducation } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import {telecostView} from '../../api/apiFun'

import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showMore, setShowMore] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [show, setShow] = useState(true);
  const [showImage, setShowImage] = useState(true);

  const [telecostData, setTeleData] = useState({})

  const handleClose = () => setShow(false);
  
  const handleCloseImage = () => {
    setShowImage(false);
    if(telecostData && telecostData?.URL){

      setShow(true);
    }
  }
  const handleShowImage = () => setShowImage(true);

const viweTelecost = async ()=>{
  await telecostView().then(result=>{

    
    if(result && result.status == true && result?.data != null){
      setTeleData(result?.data)
      setShowImage(true)
    }else{
      setShow(false)

    }
  })
}

  console.log("telecostData",telecostData);

  useEffect(() => {
    viweTelecost()
    handleShowImage()
    // handleShow()

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
      setShowMore(false);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const viewLive = (data) => {

    window.location.href = data
    // window.location.href = "https://www.youtube.com/embed/Qcpb7sa2dcs?si=QYgpKPj7-ZAdG_yD";  

};

  const handleToggleClick = () => {
    setShowMore(!showMore);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive1 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="home-page">
      <section className="banner-carousel">
        <Bannersec />
      </section>
      <section className="about-sec my-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/homePage1.jpg"}
                className="img-fluid m-auto d-block "
                alt="icon"
              />
            </div>
            <div className="col-lg-7">
              <h2 className="mb-3 element-left-align">
                {t("முகப்புதலைப்பு1")} :
              </h2>

              <p>{t("முகப்புஉள்ளடக்கம்3")}</p>

      
              <p>{t("முகப்புஉள்ளடக்கம்4")}</p>

              <p>{t("முகப்புஉள்ளடக்கம்5")}</p>

              <br></br>
              <br></br>
              <br></br>
              <br></br>

              {isMobile && (
                <>
                  <div className="show-more">
                    <button
                      onClick={handleToggleClick}
                      className="showmore-toggle"
                    >
                      {showMore ? "Show Less" : "Show More"}
                    </button>
                  </div>
                </>
              )}
            </div>

          </div>
        </div>
      </section>
      <section className="about-sec my-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h2 className="mb-3 element-left-align">
                {t("முகப்புதலைப்பு")} :
              </h2>
              <p>{t("முகப்புகருத்து")}</p>
              <p>{t("முகப்புஉள்ளடக்கம்")}</p>
              {showMore || !isMobile ? (
                <>
                  <p>{t("முகப்புஉள்ளடக்கம்1")}</p>
                  <p>{t("முகப்புஉள்ளடக்கம்2")}</p>
                </>
              ) : null}
 
              {isMobile && (
                <>
                  <div className="show-more">
                    <button
                      onClick={handleToggleClick}
                      className="showmore-toggle"
                    >
                      {showMore ? "Show Less" : "Show More"}
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-5">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/homepage2.jpeg"}
                className="img-fluid m-auto d-block "
                alt="icon"
              />
            </div>
          </div>
        </div>
      </section>
    
      <section className="vijay-bg">
        <div className="container-fluid">
          <div className="hero"></div>

          <div className="copy">
            <p className="text-center">
              {t('முகப்பு1உள்ளடக்கம்')}
            </p>
          </div>

        </div>
      </section>

      <section className="vison-mission my-5">
        <div className="container">
          <h2 className="text-center text element mb-3">
            {t('நோக்கம்பார்வை')}
          </h2>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="ecnomic d-flex gap-3">
                <div className="svgico">
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/national-ico1.png"}
                    className="img-fluid m-auto d-block "
                    alt="iconssss"
                  />
                </div>
                <div className="vision-content">
                  <h5 className="mb-4">{t('நாட்டுப்பற்று')} </h5>
                  <p>{t('நாட்டுப்பற்றுகருத்து')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ecnomic d-flex gap-3">
                <div className="svgico">
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/national-ico2.png"}
                    className="img-fluid m-auto d-block "
                    alt="icon"
                  />
                </div>
                <div className="vision-content">
                  <h5 className="mb-4">{t('கடமைகள்')}</h5>
                  <p>{t('கடமைகள்கருத்து')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ecnomic d-flex gap-3">
                <div className="svgico">
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/national-ico3.png"}
                    className="img-fluid m-auto d-block "
                    alt="icon"
                  />
                </div>
                <div className="vision-content">
                  <h5 className="mb-4">{t('சமத்துவம்')} </h5>
                  <p>{t('சமத்துவம்கருத்து')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contribute-us mb-5">
        <div className="container">
          <h2 className="mb-3 text-center text element">
            {t('பங்களிப்புதலைப்பு')}
          </h2>
          <p className="text-center mb-4">{t('பங்களிப்புகருத்து')}</p>
        </div>
        <div className="contributebtns">
          <Link to="/Joinmember" className=" joinbtn m-auto">
            {t('உறுப்பினராகஇணைய')}
          </Link>
        </div>
      </section>

      <section className="campian-videos">
        <div className="container">
          {/* <h2 className="mb-3 element-left-align">
            {t('வரவிருக்கும்பிரச்சாரவீடியோக்கள்')}
          </h2> */}

          <div className="Campaign-videos">
            <Carousels
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive1}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={1500}
              keyBoardControl={true}
              className="slider1"
              slidesToShow={1}
              slidesToScroll={1}
              transitionDuration={500}
              containerclassName="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              dotListclassName="custom-dot-list-style"
              itemclassName="carousel-item-padding-40-px"
            >
              <div className="campaign-videos">
                <div className="video-container">
                  <iframe width="1280" height="720" src="https://www.youtube.com/embed/MAl4dXK4yfg?si=rr7UO7XETlBxAgVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className="campaign-videos">
                <div className="video-container">
                  {/* <iframe
                    src="https://www.youtube.com/embed/XJ5AEeS4S-k?si=otKk8OBjeBW5ewAp"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  /> */}
                  <iframe width="1280" height="720" src="https://www.youtube.com/embed/SgbyUGrSXbU" title="சைக்கிளில் கெத்தாக வாக்களிக்க வந்த தளபதி விஜய் | Thalapathy Vijay| Vijay cycles to cast his vote" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className="campaign-videos">
                <div className="video-container">
                  {/* <iframe
                    src="https://www.youtube.com/embed/A-KO3w00G5w?si=lPTZwsIM1T-tfFlV"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe> */}
                   <iframe width="1280" height="720" src="https://www.youtube.com/embed/ASn_Me5i5-E" title="த.வெ.க -வில் சேருவது எப்படி ? - தனது ஸ்டைலில் விளக்கிய தலைவர் விஜய் | Vijay | TVK Member App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className="campaign-videos">
                <div className="video-container">
                  {/* <iframe
                    src="https://www.youtube.com/embed/umCoC5c1wXY?si=XvCTWrdX4PfgkzAj"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen=""
                  /> */}
                  <iframe width="1003" height="752" src="https://www.youtube.com/embed/pNk3rvZwbz0" title="Ilayathalapathy Vijay&#39;s Social welfare activities" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className="campaign-videos">
                <div className="video-container">
                  <iframe
                    src="https://www.youtube.com/embed/L9-tzg8NXfk?si=L5iuwW7xeMifSAI8"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen=""
                  />
                </div>
              </div>
            </Carousels>
          </div>
        </div>
      </section>
      <section className="joiningmember my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="element-left-align mb-3">{t('அரசியல்வேட்கை')} </h2>
              <p className="mb-3 tvkkalagam">{t('அரசியல்வேட்கைவிளக்கம்1')}</p>
              <p className="tvkkalagam mb-0">{t('அரசியல்வேட்கைவிளக்கம்2')}</p>
              <div className="float-end text-end">
                <h6 className="mb-0"><strong>{t('தமிழக வெற்றிக் கழகம்')} ,</strong></h6>
                <p className="mb-0 tvk-headname"><strong>{t('தலைவர் விஜய்')}</strong> </p>

              </div>

            </div>
            <div className="col-lg-6">
              <div className="gallery-sec">
                <div className="gallery">
                <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/v-1.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/v-1.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/v-2.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/v-3.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/v-4.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/img11.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/v-6.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/img10.jpg"}
                    className="img-fluid "
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {show == true && telecostData && telecostData?.URL ? <div className="live">
      <Modal className="live" show={show} onHide={handleClose} centered>
        <div className="ceremony">

        <Modal.Header closeButton>
          <Modal.Title>
            <h5><b>{t(telecostData?.tittle)} </b></h5>
            {/* <h5><b>{t('நேரடி1')} </b></h5> */}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ceremony">

            <div className="address">

            {/* <iframe width="900" height="500" src="https://www.youtube.com/embed/APkx-U2p4Cg?si=SfrFjM68Se-a3n86?autoplay=1" title="YouTube video player" frameborder="0" allow=" autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

            {/* <iframe width="900" height="500" src="https://www.youtube.com/embed/24ncOGGcRME?autoplay=1&mute=1" title="தளபதி விஜய் கல்வி விருது வழங்கும் விழா | Thalapathy Vijay Education Award Ceremony 2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                  {/* <iframe width="900" height="500" src="https://www.youtube.com/embed/CR8V4fsSLWQ?si=_v1nxo1BgwOSu_8c" title="YouTube video player" frameborder="0" allow=" autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                  {<iframe
                    width="900"
                    height="500"
                    src={telecostData?.URL} 
                    title="YouTube video player"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>}
                  {/* <iframe width="900" height="500" src={telecostData?.URL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                 {/*  <iframe
                    width="900"
                    height="500"
                    src="https://www.youtube.com/embed/b9-gnR-4wJU?si=pv0rrT4WnvdBSUqX"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen>
                  </iframe> */}



                <div className="mb-3">
                  <p>{t(`${telecostData?.tittle}news`)}</p>
                    {/* <p>{t('நேரடி2')}</p> */}

                </div>
                <button className="joinbtn" type='button'  onClick={(e)=>{viewLive(telecostData?.URL)}}>{t('நேரடி')}</button>
                  {/* <button className="joinbtn" type='button' onClick={(e) => { viewLive('https://www.youtube.com/embed/b9-gnR-4wJU?si=pv0rrT4WnvdBSUqX') }}>{t('நேரடி')}</button> */}

            </div>
          </div>
        </Modal.Body>
        </div>

      </Modal>
</div> :""}

{/* {showImage == true  ? <div className="hhhhh live">
      <Modal className="hhhhh live" show={showImage} onHide={handleCloseImage} centered>
        <div className="ceremony">

        <Modal.Header closeButton>
          <Modal.Title>
          
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ceremony">

            <div className="vijay-image">

                 <img src= "https://s3.ap-south-1.amazonaws.com/tvk.world/Telecost/_1729935110172"></img>
            </div>
          </div>
        </Modal.Body>
        </div>

      </Modal>
</div> :""} */}
</div>

  );
};

export default Home;
