import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { IoIosLogIn } from "react-icons/io";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './header.css'
import { Link, useNavigate } from 'react-router-dom';
import { TabContent, TabPane } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { signup, signin, sendRegOTP, DetailsList } from '../../../api/apiFun';
import helpmate from '../../../Helper/Common';
import { BsSunFill, BsFillMoonFill } from "react-icons/bs";
import toast from 'react-hot-toast'
import TKVLOgo from '../../../assets/images/tvk-logo.png'
import { useTranslation } from 'react-i18next'

const accessKey = process.env.REACT_APP_BSJDGSHDKBR;


const Header = () => {

    const { t, i18n } = useTranslation()
    const [selected, setSelected] = useState(localStorage.getItem('i18nextLng'));


    const theme = localStorage.getItem('clr');

    const navigate = useNavigate();
    const handleRoute = (route) => {
        navigate(`/${route}`);

    };



    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
    }, [theme]);

    const [log, setLog] = useState(helpmate.isLogin());
   



   

    const changeLanguage = (lang) => {
        setSelected(lang)
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);

    }

    const logout = () => {
        localStorage.removeItem(accessKey)
        setLog(false)
        navigate('/login');
    }



    return (




        <div className='header-sec'>
            <nav className="navbar navbar-expand-xl navbar-light">
                <div className="container-fluid">
                    <button className="btn  navbar-toggler border-3 px-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 12.75H5C4.80109 12.75 4.61032 12.671 4.46967 12.5303C4.32902 12.3897 4.25 12.1989 4.25 12C4.25 11.8011 4.32902 11.6103 4.46967 11.4697C4.61032 11.329 4.80109 11.25 5 11.25H19C19.1989 11.25 19.3897 11.329 19.5303 11.4697C19.671 11.6103 19.75 11.8011 19.75 12C19.75 12.1989 19.671 12.3897 19.5303 12.5303C19.3897 12.671 19.1989 12.75 19 12.75Z" fill="#a10101" />
                            <path d="M19 8.25H5C4.80109 8.25 4.61032 8.17098 4.46967 8.03033C4.32902 7.88968 4.25 7.69891 4.25 7.5C4.25 7.30109 4.32902 7.11032 4.46967 6.96967C4.61032 6.82902 4.80109 6.75 5 6.75H19C19.1989 6.75 19.3897 6.82902 19.5303 6.96967C19.671 7.11032 19.75 7.30109 19.75 7.5C19.75 7.69891 19.671 7.88968 19.5303 8.03033C19.3897 8.17098 19.1989 8.25 19 8.25Z" fill="#a10101" />
                            <path d="M19 17.25H5C4.80109 17.25 4.61032 17.171 4.46967 17.0303C4.32902 16.8897 4.25 16.6989 4.25 16.5C4.25 16.3011 4.32902 16.1103 4.46967 15.9697C4.61032 15.829 4.80109 15.75 5 15.75H19C19.1989 15.75 19.3897 15.829 19.5303 15.9697C19.671 16.1103 19.75 16.3011 19.75 16.5C19.75 16.6989 19.671 16.8897 19.5303 17.0303C19.3897 17.171 19.1989 17.25 19 17.25Z" fill="#a10101" />
                        </svg>
                    </button>
                    <Link to="/home" className="navbar-brand d-flex justify-content-center gap-2 align-items-center" >
                        =                        <img loading="lazy" src={process.env.PUBLIC_URL + TKVLOgo} className="img-fluid" alt="icon" />
                        <p className='mb-0'>{t('தமிழக வெற்றிக் கழகம்')}</p>
                    </Link>

                    <div className="offcanvas offcanvas-start-xl " tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header d-flex d-xl-none justify-content-between">
                            <h5 className="offcanvas-title " id="offcanvasExampleLabel">{t('தமிழக வெற்றிக்  கழகம்')}</h5>
                            <span className="text-reset p-0" data-bs-dismiss="offcanvas" aria-label="close">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="#a10101" className="bi bi-x-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </span>
                        </div>
                        <div className="offcanvas-body p-lg-0 ">
                            {helpmate.isLogin() ?   <ul className="navbar-nav m-auto">
                                <li className="nav-item ">
                                    <span onClick={() => handleRoute('')} className="nav-link " role="button" data-bs-dismiss="offcanvas" >
                                        {t('முகப்பு')}
                                    </span>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {t('கழகம்')}
                                    </a>

                                    <ul className="dropdown-menu">

                                        <li>
                                            <span onClick={() => handleRoute("announcement")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('அறிக்கைகள்')}</span>
                                        </li>
                                        <li><span onClick={() => handleRoute("pledge")} data-bs-dismiss="offcanvas" className="dropdown-item" >{t('உறுதிமொழி')}  </span></li>
                                        <li><span onClick={() => handleRoute("Organization")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('கழக அமைப்பு')}</span></li>
                                        <li><span onClick={() => handleRoute("Administrators")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('நிர்வாகிகள்')}</span></li>
                                    </ul>

                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {t('சமூக செயற்பாடு')}
                                    </a>

                                    <ul className="dropdown-menu">

                                        <li>
                                            <span onClick={() => handleRoute("Gallery")} data-bs-dismiss="offcanvas" className="dropdown-item">{t("காட்சியகம்")}</span>
                                        </li>
                                        <li><span onClick={() => handleRoute("Media")} data-bs-dismiss="offcanvas" className="dropdown-item" >{t('சமூக ஊடகம்')}  </span></li>
                                        <li><span onClick={() => handleRoute("network")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('சமூகவலைத்தளம்')} </span></li>


                                    </ul>
                                </li>
                                <li className="nav-item d-lg-none d-xxl-none">
                                    <span onClick={() => handleRoute("userdashboard")} data-bs-dismiss="offcanvas" className='nav-link' role="button">{t('முகப்புப்பெட்டி')}</span>
                                </li>
                                <li className="nav-item d-lg-none d-xxl-none">
                                    <span onClick={() => handleRoute("addpost")} data-bs-dismiss="offcanvas" className='nav-link' role="button">
                                        {t('இடுகையைச் சேர்க்கவும்')}</span>
                                </li>

                                <li className="nav-item d-lg-none d-xxl-none">
                                    <span onClick={() => handleRoute("membercard")} data-bs-dismiss="offcanvas" className='nav-link' role="button">
                                        {t('உறுப்பினர் அட்டை')}</span>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                        {t('மக்கள் சேவை')}
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <span onClick={() => handleRoute("Education")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('கல்வித்துறை')}</span>
                                        </li>
                                        <li>
                                            <span onClick={() => handleRoute("Achievements")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('வெற்றிக்கேடயம்')}</span>
                                        </li>
                                        <li>
                                            <span onClick={() => handleRoute("history")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('வரலாறு')}</span>
                                        </li>
                                        <li>
                                            <span onClick={() => handleRoute("commonevents")} data-bs-dismiss="offcanvas" className="dropdown-item">{t('பொது நிகழ்வுகள்')}</span>
                                        </li>
                                    </ul>

                                </li>
                                <li className="nav-item ">
                                    <span onClick={() => handleRoute('poster')} className="nav-link " role="button" data-bs-dismiss="offcanvas" >
                                        {t('பதிவுகள் ')}
                                    </span>
                                </li>
                                <li className="nav-item ">
                                    <span onClick={() => handleRoute('conference')} className="nav-link " role="button" data-bs-dismiss="offcanvas" >
                                        {t('பதிவுகள்1 ')}
                                    </span>
                                </li>
                            </ul> :   <ul className="navbar-nav m-auto">
                               
                                
                            </ul>}
                          
                            <form className="form-btns d-flex gap-2">
                                <select className="language-selector" value={selected} onChange={(e) => changeLanguage(e.target.value)}>
                                    <option value="en">ENGLISH</option>
                                    <option value="tam">தமிழ்</option>
                                </select>
                                {helpmate.isLogin()  ? (
                                    <>
                                        <button className="joinbtn" type="button" onClick={() => handleRoute("userdashboard")}>
                                            {t('முகப்புப்பெட்டி')}
                                        </button>
                                        <button className="joinbtn" type="button" onClick={logout}>
                                            {t('வெளியேறு')}
                                        </button>
                                    </>
                                ) : (
                                    <button className="joinbtn" type="button" onClick={() => handleRoute('login')}>
                                        {t('Login')}
                                    </button>
                                )}
                                {helpmate.isLogin() ? "" : <button className=" joinbtn" type="button" onClick={() => handleRoute('register')}>{t('Register')}</button>}


                                {/* {toggleTheme === 'dark' ? <i onClick={()=>handleTheme()} style={{cursor : 'pointer', padding : '10px'}} className='moon'><BsFillMoonFill/></i> : <i onClick={()=>handleTheme()} style={{cursor : 'pointer', padding : '10px'}} className="sun"><BsSunFill/></i>} */}


                            </form>
                        </div>

                    </div>
                </div >
            </nav >
        </div >
    )
}

export default Header